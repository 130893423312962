<template>
  <div >
    <br><br><br><br><br><br> 

    <div class="row justify-content-center mt-5"> 
    <div class="col-md-1 col-12" v-if="!isMobile"></div>
    <div class="col-md-11 col-12" >
      <!-- <u class="ml-4" v-if="isMobile" :style="isMobile ? 'cursor: pointer;  font-size:20px; color:#3ABF30;' : 'cursor: pointer;  margin-left: 975px; font-size:20px; color:#3ABF30;'" @click="regresaList()">Regresar</u> -->
      <h1 :class="!isMobile ? 'mt-3' : 'mt-4 ml-4'" :style="isMobile ? 'font-size: 20px;' : 'font-size: 26px;'">Proyecto:<b> {{obj.proyecto}}</b></h1>
         <div :class=" isMobile ? 'row justify-content-center ' : 'row justify-content-center mt-5'">
           <div class="col-md-11 col-12 mt-3" >
              <b-alert variant="success" show style="color:#000;  background: #D5E4C5; border-color:#D5E4C5; font-size:15px">
                 <div class="row"> 
                   <div class="col-md-6 col-11" >
                     <b>Manager Regional:</b> {{obj.usuario_responsable}} <br> 
                     <b>Plaza(s):</b> {{obj.plaza}}
                   </div>
                   <div class="col-md-2 col-11" >
                     <b>Región:</b> {{obj.region}} <br> 
                     <b>Fecha envío:</b>  <template v-if="obj.fecha_entrega != '0000-00-00'">{{obj.fecha_entrega}}  </template>
                   </div>
                   <div class="col-md-2 col-11" >
                     <b>Bodega:</b> {{obj.bodega}}
                   </div>
                   <div class="col-md-2 col-11" >
                     <b>Territorio:</b> {{obj.territorio}}
                   </div>
                 </div>    
              </b-alert>
              <br> 
           </div>
           <div class="col-md-1"></div>
           <div class="col-md-10 col-11 row justify-content-center" >  
                  <div  v-for="det  in arrayPromo" :key="det.id"  :class="isMobile ? 'col-md-6 mt-1'  : 'col-md-4'">
                    <br v-if="!isMobile">
                    <center>
                      <h4>{{!det.nombre ? det.nombre2 : det.nombre}}</h4> 
                      <b style="color:#00A9E0; cursor: pointer;">Cantidad {{det.cantidad}}</b><br>
                      <span style="color: #818181;">Disponible {{det.existente}}</span>   
                      <br><br> 
                      <img v-if="det.nombre" :src="'https://controlpromocionales.asistentemovistar.com.mx/imgs/' + det.foto" alt="Image" :width="isMobile ? 275 : 270"> 
                      <img v-else :src="'https://controlpromocionales.asistentemovistar.com.mx/imgs/'+ det.foto" alt="Image" :width="isMobile ? 275 : 270"> 
                      <br><br>
                      <hr style="border-top: 5px solid #5BC500 !important;  opacity :2; width: 50%;"> 
                    </center> 
                </div>   
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-10 col-11 text-center" >  
                <br> <br><br>
                <b style="font-size:20px">Estatus:
                      <template v-if="obj.estatus == 0">
                        <span style="color:#00A9E0;">Cancelado</span> 
                      </template>
                      <template v-if="obj.estatus == 1">
                        <span style="color:#00A9E0;">Confirmado</span> 
                      </template>
                      <template v-if="obj.estatus == 2">
                        <span style="color:#00A9E0;">Enviado</span> 
                      </template>
                </b> 
                <br><br> 
                <div class="row justify-content-center mt-2 " >  
                   <div class="col-md-2 col-6 text-center" v-if="obj.testigo">
                      <button  @click="verTest(obj)" class="btn btn-block btn-light float-center  mt-3" type="button"
                        style="color:#fff; border-radius: 26px;  background:#5BC500; border-color:#5BC500; font-size:14px;">
                          <b> Ver Testigo</b>
                      </button> 
                   </div> 
                </div>  
                <br><br>
            </div> 
              <div class="col-md-1"></div>
         </div>
    </div> 
   </div> 

    <b-modal id="promos" title="Testigo Recepción" hide-footer size="lg" > 
      <div class="row justify-content-center">  
        <div class="col-7">
            <!-- <b>Testigo fotografico</b> -->
            <br>
            <img crossorigin="anonymous" :src="$api.baseUriFiles+'control_promocional/' + obj.testigo" alt="Image"  :width="isMobile ? 250 : 400">
        </div>   
         <div class="col-12"><br><br></div>
      </div>  
    </b-modal>

  </div>
</template>
<script> 
import moment  from 'moment'; 
export default {
    name:"Promocionales", 
     data() {
        return {
          isMobile: this.$mobile(),
          obj:{},
          arrayPromo:[],
          arrayComent:[],
          folio:0
        }
     },
    computed: {
        user() {
            return this.$store.getters.getInfoUser;
        },
    },  
    filters: {
        fechaA:function(value) {
            if (value) {
                moment.locale('es')
                return moment(String(value)).format('YYYY-MM-DD')
            }
        },
        fechaFormato:function(value) {
            if (value) {
                moment.locale('es')
                return moment(String(value)).format('YYYY-MM-DD  LTS')
            }
        },
    },
    methods:{ 
        verTest(item){
        this.obj = item;
        this.$bvModal.show('promos');
        },
        consultaInfo(){
         var url= 'control_promo/getinfo?id='+ this.folio;
            this.$api.get(url).then(
                    ({data}) => { 
                        this.obj = data.data.list; 
                    }
                );
        },
        verPromo(){ 
            var url= 'control_promo/promos?id='+ this.folio;
                this.$api.get(url).then(
                        ({data}) => { 
                            this.arrayPromo = data.data.promos;
                            this.arrayComent = data.data.comentario; 
                        }
                    );
        },
    },
    mounted(){
        this.folio = this.$route.params.folio;
        this.consultaInfo();
        this.verPromo();
    }
}

 
</script> 